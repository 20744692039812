<template>
    <div class="container">
        <router-view />
    </div>
</template>
<script>
export default {
    name: 'DataCheckupIndex'
}
</script>
